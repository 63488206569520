import React from "react";
import { Button, ButtonProps } from "@mui/material";

interface RoundedButtonProps extends ButtonProps {}

const roundedButtonStyles = {
  borderRadius: "5px",
  padding: "10px 40px",
  fontSize: "20px",
  backgroundColor: "#2767D4",
  color: "white",
  border: "none",
  cursor: "pointer",
  "&:hover": {
    backgroundColor: "#057ACC",
  },
};

const RoundedButton: React.FC<RoundedButtonProps> = ({
  children,
  ...props
}) => {
  return (
    <Button style={roundedButtonStyles} {...props}>
      {children}
    </Button>
  );
};

export default RoundedButton;
